.single-card-style {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin: 10px;
  border-color:black;
}

.note-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em;
  max-height: 4.2em;
}

.note-content-style {
  font-size: "25px";
  display: "flex";
  flex-direction: "column";
  min-height: "100%";
}

.button-style {
   display: flex;
  justify-content: space-between;
  align-items: flex-end;    
}
.button-style1 {
  border-color:"#050092";
  color: "#050092";
}
.scrollable-textfield {
  overflow-y: scroll;
  max-height: 12em; /* Adjust the max-height as needed */
}

