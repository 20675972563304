.page-container {
  position: relative;
  min-height: 81vh;
}

.table__header {
  cursor: pointer;
}

.table__header:hover {
  font-weight: 1000;
  color: #2340b3 !important;
}

.product__button {
  background-color: #eaedf2 !important;
}

.detail__button {
  background-color: #fff !important;
}

.item__button {
  background-color: #fff !important;
  width: 40%;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 00% !important;
  border-bottom-left-radius: 00% !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 00% !important;
  border-bottom-left-radius: 00% !important;
}

.accordion-flush .accordion-item {
  border-bottom: 0;
}

.no__background {
  background-color: #ffffff !important;
  border-color: #fff;
}

td {
  /* word-wrap: break-word; */
  white-space: normal !important;
}

/* table {
  table-layout: fixed;
} */

.search-term {
  position: relative;
  padding: 0.5rem 0.5rem !important;
}

.search-term .cross-btn {
  position: absolute;
  top: 40%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #fff;
  color: red;
  font-size: 16px;
  padding: 4px 6px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
