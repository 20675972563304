.header-cell {
  background-color: #e3f0ff !important;
}
.header-cell-total {
  background-color: #050092 !important;
  color: white;
}
.center-align-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #050092;
  background-color: #e3f0ff !important;
}
.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-align-total {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #050092 !important;
  background-color: #e3f0ff !important;

}
