/* Sidebar.css */
.accordion-item {
  background-color: #262b40 !important;
  border: #262b40 !important;
}

.accordion-button {
  background-color: #262b40 !important;
}

/* .sidebar-text {
  color: aqua;
} */

.sidebar-inner .accordion-button::after {
  background-image: none;
  content: none !important;
  font-family: "Font Awesome 5 Free";
  font-size: 0.875rem;
  font-weight: 900;
  height: 1.25rem;
  width: 0.5rem;
}

.custom-accordion-button.accordion-sidebar:not(.collapsed) {
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 #262b40 !important;
}

.sidebar-inner-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  overflow: hidden;
  position: relative;
}

.sidebar-inner {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 80px;
}

.logout-button {
  position: sticky;
  bottom: 0;
  padding: 10px 15px;
  background-color: #34394e;
  z-index: 1;
}
