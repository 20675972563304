.order__form {
  border-right: 1px dashed #d1d7e0;
}

.form-check-input:checked {
  background-color: #05a677;
  border-color: #262b40;
}

.page-container {
  position: relative;
  min-height: 81vh;
}

.info__card {
  background-color: #6c757d;
}

.bg-mysecondary {
  background-color: #868d94;
}

.icon_upload {
  color: #210be2;
}

.my-yellow {
  color: rgb(224, 128, 17);
}

.my-secondary {
  color: rgb(88, 94, 94);
}

.green-btn {
  background-color: #05a677;
}

.blue-btn {
  background-color: rgb(26, 92, 153);
}

.workcenter-body {
  padding: 0.15rem 0.1rem !important;
}

.workcenter-header {
  padding: 0.5rem 0.7rem !important;
}
