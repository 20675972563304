.flip-card {
  perspective: 1000px;
  margin-bottom: 10px;
}

.flip-card-inner {
  width: 100%;
  min-height: 200px;
  transform-style: preserve-3d;
  transition: transform 1.3s;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  min-height: 100px;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  /* Styles for the front side of the card */
}

.flip-card-back {
  /* Styles for the back side of the card */
  transform: rotateY(180deg);
}
